import { React } from 'react'
import { Route, Routes } from 'react-router'
import Home from '../home/Home'
import CustodyEvaluations from '../infoPages/CustodyEvaluation'
import ContactPage from '../contact/ContactPage'
import About from '../infoPages/About'

function SiteRoutes() {
	return (
		<>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/services/evaluations' element={<CustodyEvaluations />} />
				<Route path='/contact' element={<ContactPage />} />
				<Route path='/about' element={<About />} />
			</Routes>
		</>
	)
}

export default SiteRoutes
