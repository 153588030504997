import React from 'react'
import ContactForm from './ContactForm'

function ContactPage() {
	return (
		<div className='infoPage infoPage-service'>
			<div className='infoPage-wrapper'>
				<div className='infoPage-container'>
					<h1 className='mb-4 pt-4'>Need to get in contact?</h1>
					<p className=''>
						You can use the number below or the contact form to send a message
					</p>
					<p style={{ marginTop: '-1%' }}>
						Phone Number: <a href='tel:6518082514'>651-808-2514</a>
					</p>
					<div className='row gx-0 mt-5'>
						<div className='col-lg'>
							<i>
								<h2>Contact Form</h2>
							</i>
							<ContactForm />
						</div>
						<div className='col-lg'>
							<i>
								<h2>Office Location</h2>
							</i>
							<div className='embed-responsive embed-responsive-16by9'>
								<iframe
									title='map'
									width='90%'
									height='400'
									src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=7572%20arbor%20ln%20savage%20mn+(New%20Beginnings)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
								>
									<a href='https://www.maps.ie/distance-area-calculator.html'>
										measure distance on map
									</a>
								</iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactPage
