import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

function NavBar() {
	return (
		<Navbar
			expand='md'
			bg='dark'
			data-bs-theme='dark'
			className='bg-body-tertiary'
		>
			<Container className='flex-md-column'>
				<Navbar.Brand className='mb-0 h1 fs-1' href='/'>
					New Beginnings Law LLC
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='me-auto mb-0 h1 fs-5'>
						<Nav.Link href='/about'>About</Nav.Link>
						<Nav.Link href='/contact'>Contact</Nav.Link>
						<Nav.Link href='/services/evaluations'>Services</Nav.Link>
						<Nav.Link
							href='https://apps.timesolv.com/InvoicePayment/PaymentFormEmbed.aspx?t=MX99WhIo0WVyb7tRjuGVQD0x3DXRUk%2fBolSBkMHUTYa1BY%2bgpLXCj%2bsh2FL28Rhk56niUehS3RcWvnHOc9xhMA%3d%3d&m=KFa6S4GgMqXFmcUIffvEmjCfdnoUoPlmlXPzapk7o1nE48zeh1z%2bwERq3ybc0dcoBSrwKGFJCGG30ImsbVBYoQ%3d%3d'
							target='_blank'
						>
							Pay Your Bill
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default NavBar
