import { React, useRef } from 'react'
import { Form, Input, TextArea, Button } from 'semantic-ui-react'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'
import '../../styles/NewContactForm.css'

function ContactForm() {
	const SERVICE_ID = 'service_xr0lifu'
	const TEMPLATE_ID = 'template_pf30can'
	const KEY = 'hLCWvYHueaX70FOit'

	async function handleSubmit(e) {
		e.preventDefault()
		const data = Object.fromEntries(new FormData(e.target))
		try {
			let res = await emailjs.send(SERVICE_ID, TEMPLATE_ID, data, KEY)
			Swal.fire({ icon: 'success', title: 'Message Sent Successfully' })
			console.log(res)
			console.log(data)
		} catch (error) {
			console.log(error)
			Swal.fire({
				icon: 'error',
				title: 'Oops, something went wrong',
				text: error.text,
			})
		}

		e.target.reset()
	}

	return (
		<div className='ContactForm-container'>
			{/* <form className='form' onSubmit={handleSubmit}>
				<div className='input'>
					<label htmlFor='name'>Your Name</label>
					<input
						type='text'
						name='name'
						value={formData.name}
						onChange={handleChange}
					/>
				</div>
				<div className='input'>
					<label htmlFor='email'>Your Email</label>
					<input
						type='email'
						name='email'
						value={formData.email}
						onChange={handleChange}
					/>
				</div>
				<div className='input'>
					<label htmlFor='subject'>Subject</label>
					<input
						name='subject'
						value={formData.subject}
						onChange={handleChange}
					/>
				</div>
				<div className='input'>
					<label htmlFor='number'>Phone Number</label>
					<input
						name='number'
						value={formData.message}
						onChange={handleChange}
					/>
				</div>
				<div>
					<button onClick={handleSubmit}>Send Message →</button>
				</div>
			</form> */}

			<Form onSubmit={handleSubmit}>
				<Form.Field
					id='form-input-control-email'
					control={Input}
					label='Email'
					name='user_email'
					placeholder='Email…'
					required
					icon='mail'
					iconPosition='left'
				/>
				<Form.Field
					id='form-input-control-last-name'
					control={Input}
					label='Name'
					name='user_name'
					placeholder='Name…'
					required
					icon='user circle'
					iconPosition='left'
				/>
				<Form.Field
					id='form-textarea-control-opinion'
					control={TextArea}
					label='Message'
					name='user_message'
					placeholder='Message…'
					required
				/>
				<Button type='submit' color='green'>
					Submit
				</Button>
			</Form>
		</div>
	)
}

export default ContactForm
