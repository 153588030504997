import React from 'react'
import BBBlogo from '../../assets/BBBlogo.png'

function MissionStatement() {
	return (
		<div className='infoPage MissionStatement'>
			<div className='infoPage-wrapper'>
				<div className='infoPage-container'>
					<i>
						<h2 className=''>Vision Statement</h2>
					</i>
					<p>
						New Beginnings Law is committed to providing legal services with an
						emphasis on responsiveness and assistance to parents engaged in
						divorce and custody determinations with a goal of lessening the
						anxiety inherent in that process. We are also committed to dealing
						with each each client with respect and to providing service and
						information to help them understand the process and achieve a fair
						outcome keeping the best interest of the children first and
						foremost.
					</p>
					<br />
					<i>
						<h2>Mission Statement</h2>
					</i>
					<p>
						New Beginnings Law strives to help families experiencing anxiety and
						uncertainty navigate the legal process with an experienced family
						law attorney and evaluator and to provide neutral assessments and a
						fair resolution in the best interest of the children. We carry out
						our mission with integrity.
					</p>
					<br />
					<i>
						<h2>Guiding Principles</h2>
					</i>
					<ul style={{ 'list-style-type': 'none' }}>
						<li>
							We are committed to providing client service with a standard of
							excellence
						</li>
						<hr />
						<li>
							We are committed to making an impact on the community in which we
							work
						</li>
						<hr />
						<li>
							We are committed to serving each client with respect and
							individuality
						</li>
						<hr />
						<li>
							We are committed to providing thorough and neutral assessments
						</li>
						<hr />
						<li>
							We are committed to objective analysis of all information provided
						</li>
						<hr />
						<li>
							We are committed to determinations based upon the best interests
							of the children
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default MissionStatement
