import React from 'react'

function CustodyEvaluations() {
	return (
		<div className='infoPage infoPage-service CustodyEvaluations'>
			<div className='infoPage-wrapper'>
				<div className='infoPage-container'>
					<i>
						<h1 className='mb-4 pt-4'>Child Custody Evaluations</h1>
					</i>
					<p>
						Although custody evaluations are not mandatory in every custody
						case, the court may order a report from a neutral party in those
						cases that are contested. The neutral party is called a custody
						evaluator, a neutral party assigned to investigate the circumstances
						of the family to determine a custody and parenting time schedule
						that is in the best interest of the children.
					</p>
					<p>
						The process may take up to six months to complete, during which the
						custody evaluator meets with the parents separately two or three
						times, with the children in the parents' homes and separately in the
						evaluator's office. In addition, the court or evaluator may require
						a psychological evaluation of the parents or children and may order
						release of medical and psychological records of the children and
						parents. It may involve a drug and alcohol assessment of the
						parents, a criminal background check of the parents, day care and
						school records and information from collateral sources.
					</p>
					<p>
						The investigation is very thorough and the timing depends a great
						deal on how quickly the parents comply with requests for
						information. Following the investigation, Ms. Krieg will complete a
						preliminary report that addresses the “best interest” factors under
						Minnesota Statute § 518.17. This is an analysis based upon all the
						information gathered during investigation phase.
					</p>
					<p>
						While every custody evaluation is different, the phases of the
						assessment generally take the following time:
					</p>
					<br />
					<ul style={{ 'list-style-type': 'none' }}>
						<li>
							Parental interviews: 2-3 with each parent, totaling up to 6 hours
						</li>

						<li>
							Childrens' Interviews: 1 hour with each child, and 1 hour with
							children in each parent's home
						</li>

						<li>
							Phone contact with the children's day care provider and teachers,
							counselors and physicians
						</li>

						<li>
							Phone contact with other adults with a close relationship with the
							children
						</li>

						<li>
							Phone contact with collateral sources identified by the parents
						</li>

						<li>Criminal background check of each parent</li>

						<li>Review of the parents' psychological evaluations</li>

						<li>Review of the parents' drug and alcohol assessments</li>

						<li>
							Reviewing the parents' and children's medical and psychologic and
							therapeutic records
						</li>

						<li>
							Any other information pertinent to determining the children's best
							interest with respect to custody
						</li>

						<li>
							Drafting the initial report and presenting it to the attorneys of
							record, which takes many hours of review and preparation, followed
							by actual drafting
						</li>
					</ul>
					<br />
					<p>
						Fees are charged at an hourly rate of $250.00 and an initial
						retainer of $4,000.00 is required before any work will be started.
						Once the retainer is depleted to $1,000.00, a refresh of $2,000 is
						required, and no work will be provided if the retainer is depleted
						at any time during the process.
					</p>
					<p>
						Finally, if you have an attorney, collaborate with him or her on
						legal strategy and for discussion of the information needed to be
						provided by the parties.
					</p>
					<p>
						If interested in this service please refer to the{' '}
						<a href='/contact'>contact page</a>
					</p>
					<br />
					<br />
				</div>
			</div>
		</div>
	)
}

export default CustodyEvaluations
