import React from 'react'
import headShot from '../../assets/Nancy.png'

function About() {
	return (
		<div className='infoPage About'>
			<div className='infoPage-wrapper'>
				<div className='infoPage-container About'>
					<div>
						<i>
							<h1 className='mb-4 pt-4'>About Nancy Krieg</h1>
						</i>
					</div>
					<div>
						<img className='headshot' src={headShot} alt='Nancy Headshot' />
					</div>
					<p>
						Nancy Krieg graduated magna cum laude from John F. Kennedy
						University in Pleasant Hill, California in 2011. In 2014, she
						received a Doctorate of Law (JD) from William Mitchell School of Law
						and has practiced in the family law arena since then. Her background
						in representing clients in divorce matters provides a broad
						background and experience. Throughout her career, Ms. Krieg has
						focused on maintaining good client relationships by being prompt and
						thorough in communication and responses and by keeping clients
						focused on the children's interests.
					</p>
					<p>
						Her latest endeavor is working with families to reach child-centered
						solutions to resolve custody and parenting issues through
						Alternative Dispute Resolution methods. Ms. Krieg's Alternative
						Dispute Resolution practice aims to make the services convenient and
						comfortable for the clients. There is no charge for the initial
						consultation, and all services may be utilized in person or via
						remote technology if a client is unable to meet in person. New
						Beginnings ADR accepts clients from all over Minnesota.
					</p>
					<p>
						New Beginnings currently provides:
						<a href='/services/evaluations'>
							Parenting Time/Custody Evaluations
						</a>
					</p>
					<p>
						Mediation and Parenting Consulting will be offered in the near
						future.
					</p>
					<p>
						New Beginnings Law has been awarded accreditation by the Better
						Business Bureau of Minnesota and North Dakota. This firm is
						committed to integrity and ethical business practices, and remains
						dedicated to customer service.
					</p>
				</div>
			</div>
		</div>
	)
}

export default About
