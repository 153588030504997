import './App.css'
import Home from './components/home/Home'
import NavBar from './components/nav/NavBar'
import SiteRoutes from './components/nav/SiteRoutes'

function App() {
	return (
		<div className='App'>
			<NavBar />
			<SiteRoutes />
		</div>
	)
}

export default App
