import '../../styles/Home.css'
import React from 'react'
import MissionStatement from '../infoPages/MissionStatement'

function Home() {
	return (
		<div className='home'>
			<div className='blur'>
				<div className='home-content-container'>
					<div className='home-content-wrapper'>
						<h1 className='content-h1'>Welcome to New Beginnings Law</h1>
						<h4 className='content-h4'>
							Custody Evaluations | Counseling | Legal Advice
						</h4>
						<h3 className='content-h3'>
							Legal services with someone who cares
						</h3>
					</div>
				</div>
			</div>
			<div className='home-statement'>
				<MissionStatement />
			</div>
		</div>
	)
}

export default Home
